import { getWebsiteSettings } from "@/data/get-website-settings"
import { getNavigations } from "@/data/navigations"
import { MainLayout } from "@/layout/MainLayout"
import { Button, Flex, Text } from "@mantine/core"
import { GetStaticProps, InferGetServerSidePropsType } from "next"
import { useTranslation } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"

export default function NotFoundPage(props: InferGetServerSidePropsType<typeof getStaticProps>) {
  const { t } = useTranslation("common")

  return (
    <MainLayout navigations={props.navigations} settings={props.websiteSettings}>
      <Flex
        p={0}
        m={0}
        bg={"linear-gradient(180deg,#ebfffd,#efeefa)"}
        h={{ base: 550 }}
        justify={"center"}
        align={"center"}
        pos={"relative"}
      >
        <Flex pos={"relative"} w={750}>
          <Flex
            p={0}
            m={0}
            pos={"absolute"}
            top={{ base: "50%" }}
            left={{ base: "50%" }}
            style={{ zIndex: 1, transform: "translate(-50%, -50%)" }}
            h={{ base: 130, sm: 200 }}
          >
            <img style={{ height: "100%" }} src="https://tamda.thietkeweb.cz/images/others/404.svg" alt="404" />
          </Flex>
          <Flex
            p={0}
            m={0}
            pos={"absolute"}
            top={{ base: "50%" }}
            left={{ base: "50%" }}
            style={{ zIndex: 2, transform: "translate(-50%, -50%)" }}
            direction={"column"}
            align={"center"}
          >
            <Text fz={{ base: 30, sm: 52 }} fw={{ base: 900 }}>
              {t("Page not found", "Page not found")}
            </Text>
            <Text fz={{ base: 14, sm: 18 }} fw={{ base: 400 }} mt={{ base: 15, sm: 20 }}>
              {t(
                "Sorry, the content you are looking for is not exist",
                "Sorry, the content you are looking for is not exist",
              )}
            </Text>
            <Button variant="filled" mt={{ base: 16, sm: 28 }} h={{ base: 45, sm: 56 }} c={"#157E40"}>
              <Text fw={{ base: 600 }} fz={{ base: 18 }} c={"white"}>
                {t("Go back", "Go back")}
              </Text>
            </Button>
          </Flex>
        </Flex>
        <img
          style={{ position: "absolute", bottom: 70, left: "40%", zIndex: 1 }}
          src="https://tamda.thietkeweb.cz/images/shape/404-01.svg"
          alt="shape"
        />
        <img
          style={{ position: "absolute", bottom: 70, right: "20%", zIndex: 1 }}
          src="https://tamda.thietkeweb.cz/images/shape/404-01.svg"
          alt="shape"
        />
        <Flex
          pos={"absolute"}
          bottom={{ base: 30, sm: 0 }}
          left={{ base: 0 }}
          style={{ zIndex: 1 }}
          h={{ base: 100, sm: 250 }}
        >
          <img style={{ height: "100%" }} src="	https://tamda.thietkeweb.cz/images/shape/404-03.svg" alt="shape" />
        </Flex>
        <Flex pos={"absolute"} top={0} w={"100%"} h={"100%"}>
          <img
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            src="https://tamda.thietkeweb.cz/images/shape/404-02.svg"
            alt="shape"
          />
        </Flex>
      </Flex>
    </MainLayout>
  )
}

interface Props {
  navigations: Awaited<ReturnType<typeof getNavigations>>
  websiteSettings: Awaited<any>
}

export const getStaticProps: GetStaticProps<Props> = async ({ locale = "en" }) => {
  const navigations = await getNavigations(locale)
  const websiteSettings = await getWebsiteSettings()

  return {
    props: {
      ...(await serverSideTranslations(locale ?? "en", ["common"])),
      navigations,
      websiteSettings,
    },
  }
}
